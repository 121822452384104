<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >

    <div v-if="users !== null">

      <user-list-add-new
          :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
          @refetch-data="refreshData"
      />

      <!--     Filters-->
      <!--    <users-list-filters-->
      <!--      :role-filter.sync="roleFilter"-->
      <!--      :plan-filter.sync="planFilter"-->
      <!--      :status-filter.sync="statusFilter"-->
      <!--      :role-options="roleOptions"-->
      <!--      :plan-options="planOptions"-->
      <!--      :status-options="statusOptions"-->
      <!--    />-->

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <span> تعداد کاربران : </span>
              <span class="mx-1">{{ totalCounts }}</span>
              <span>نفر</span>
            </b-col>

            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>تعداد</label>
            </b-col>

            <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="perState"
                  :options="perStateOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50 w-50"
              />
              <label>وضعیت</label>
            </b-col>

            <!-- Search -->
            <b-col
                cols="12"
                md="5"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="debouncedSearch"
                    class="d-inline-block mr-1"
                    placeholder="جستجو..."
                />
                <b-button
                    variant="primary"
                    @click="isAddNewUserSidebarActive = true"
                >
                  <span class="text-nowrap">اضافه کردن کاربر</span>
                </b-button>
              </div>
            </b-col>

            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start my-1 mb-md-0"
            >
              <b-button
                  variant="success"
                  class=""
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="downloadFile"
              >
                دانلود اکسل لیست
              </b-button>
            </b-col>
          </b-row>

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="users"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >

          <template #cell(name)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="45"
                    :src="`https://api.maxterms.com/${data.item.selfieFileData}`"
                    :variant="`success`"
                    :to="{ name: 'apps-users-edit', params: { id: data.item.userId } }"
                />
              </template>
              <b-link
                  :to="{ name: 'apps-users-edit', params: { id: data.item.userId } }"
                  class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.name + ' ' + data.item.familyName }}
              </b-link>
              <small class="text-muted">@{{ data.item.userId }}</small>
            </b-media>
          </template>

          <!-- Column: type -->
          <template #cell(type)="data">
            <b-badge
                pill
                class="text-capitalize"
                :variant="``"
            >
              {{ data.item.type }}
            </b-badge>
          </template>


          <!-- Column: state -->
          <template #cell(state)="data">
            <b-badge
                v-if="data.item.state === 1"
                pill
                class="text-capitalize"
                :variant="`success`"
            >
              وارد شده
            </b-badge>
            <b-badge
                v-else-if="data.item.state === 2"
                pill
                class="text-capitalize"
                :variant="`warning`"
            >
              فالو نکرده
            </b-badge>
            <b-badge
                v-else-if="data.item.state === 3"
                pill
                class="text-capitalize"
                :variant="`danger`"
            >
              آیدی اشتباه
            </b-badge>
          </template>

          <!-- Column: edit -->
          <template #cell(edit)="data">
            <b-link :to="{ name: 'apps-users-edit', params: { id: data.item.userId } }">
              <feather-icon icon="EditIcon" size="20"/>
            </b-link>
          </template>

          <!-- Column: buy -->
          <template #cell(buy)="data">
            <b-link :to="{ name: 'apps-buy-id', params: { id: data.item.userId } }">
              <feather-icon icon="ShoppingCartIcon" size="20"/>
            </b-link>
          </template>

          <!-- Column: chat -->
          <template #cell(chat)="data">
            <b-link :to="{ name: 'apps-chats-id', params: { id: data.item.userId } }">
              <feather-icon icon="MessageCircleIcon" size="20"/>
            </b-link>
          </template>

          <!-- Column: createDate -->

          <template #cell(createDate)="data">

            <small class="">
              {{data.item.createDate.slice(11,16)}}
              -
              {{createJalaliDate(data.item.createDate.slice(0,10))}}
            </small>

          </template>

          <!--        &lt;!&ndash; Column: factors &ndash;&gt;-->
          <!--        <template #cell(factors)="data">-->
          <!--          <b-link :to="{ name: 'apps-users-factors', params: { id: data.item.userId } }">-->
          <!--            <feather-icon icon="ListIcon" />-->
          <!--          </b-link>-->
          <!--        </template>-->

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCounts"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import UsersListFilters from './UsersListFilters.vue'
import UserListAddNew from './UserListAddNew.vue'
import {UsersGetAllRequest} from "@/libs/Api/Users";
import {getUserData} from "@/auth/utils";
import axiosIns from "@/libs/axios";
import {saveAs} from 'file-saver';

export default {
  title: "لیست کاربران - پنل ادمین مکس ",
  data() {
    return {
      users: null,
      overlay:false,
      currentPage: 1,
      totalCounts: null,
      perPage: 50,
      perState: '',
      perStateOptions: [
        {label: 'همه', value: ''},
        {label: 'بلاک شدگان', value: true},
      ],
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {key: 'name', label: 'نام کامل'},
        {key: 'instagramId', label: 'اینستاگرام'},
        {key: 'nationalId', label: 'کد ملی'},
        {key: 'createDate', label: 'تاریخ ثبت نام'},
        {key: 'state', label: 'وضعیت'},
        {key: 'mobile', label: 'موبایل'},
        {key: 'edit', label: 'ویرایش کاربر'},
        {key: 'buy', label: 'ثبت سفارش'},
        {key: 'chat', label: 'چت'},
        // { key: 'factors',label:'فاکتورهای کاربر'},
      ],
      searchQuery: '',
      timeOut: null,
      isAddNewUserSidebarActive: false,
    }
  },
  async created() {
    await this.getUsers(this.perPage, this.currentPage, this.searchQuery, this.perState)
  },
  methods: {
    refreshData() {
      this.getUsers(this.perPage, this.currentPage, this.searchQuery, this.perState)
    },
    async getUsers(count, pageNumber, search, state) {
      let _this = this;
      _this.overlay = true;
      let data = {
        pageNumber: pageNumber,
        count: count,
        search: search,
        state: '',
        isBlocked:state
      }

      let usersGetAllRequest = new UsersGetAllRequest(_this);
      usersGetAllRequest.setParams(data);
      await usersGetAllRequest.fetch(function (content) {
        _this.overlay = false;
        _this.users = content.users;
        _this.totalCounts = content.usersCount;
      }, function (error) {
        _this.overlay = false;
        console.log(error);
      })
    },
    async downloadFile() {
      let _this = this;
      _this.overlay = true;
      let userToken = getUserData().data.token;

      await axiosIns.post(`Users/GetUserExcel`, null, {
        responseType: 'blob',
        'Authorization': userToken,
      }).then(resp => {
        _this.overlay = false;
        saveAs(resp.data, 'لیست کاربران.xlsx');
      })
          .catch((error) => {
            _this.overlay = false;
            console.log(error);
          });
    },
    createJalaliDate(param){
      return new Date(param).toLocaleDateString("fa-IR",);
    },
  },
  watch: {
    perPage: function (nv, ov) {
      this.getUsers(nv, this.currentPage, this.searchQuery, this.perState);
    },
    currentPage: function (nv, ov) {
      this.getUsers(this.perPage, nv, this.searchQuery, this.perState);
    },
    searchQuery: function (nv, ov) {
      this.getUsers(this.perPage, this.currentPage, nv, this.perState);
    },
    perState: function (nv, ov) {
      this.getUsers(this.perPage, this.currentPage, this.searchQuery, nv);
    }
  },
  computed: {
    debouncedSearch: {
      get() {
        return this.searchQuery
      },
      set(val) {
        if (this.timeOut) {
          clearTimeout(this.timeOut)
        }
        this.timeOut = setTimeout(() => {
          this.searchQuery = val
        }, 600)
      }
    }
  },
  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BInputGroup, BInputGroupAppend,

    vSelect,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
